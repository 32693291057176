/* eslint-disable react/no-unknown-property */
import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

import './styles.scss';

import countries from '../../data/countries.json';
import allContinents from '../../data/continents.json';

import slugs from '../../data/slugs.json';

const countryContinents = [...new Set(countries.map((el) => el.continent))];

const sorted = {
  europe: 10,
  asia: 9,
  caribbean: 8,
  indianocean: 8,
  middleeast: 7,
  northamerica: 6,
  africa: 5,
};

const continents = allContinents.filter((el) => countryContinents.indexOf(el.ftid) > -1).sort((el, el2) => (sorted[el.ftid] > (sorted[el2.ftid] || 0) ? -1 : 1));
const getSlug = (ftid) => `${slugs.find((el) => el.ftid === ftid).slug}/`;

const IndexPage = () => (
  <Layout>
    <SEO title="Pogoda na świecie" />
    <div class="container">
      {/* { JSON.stringify(pageContext) } */ }
      <section class="section">
        <h1 class="title has-text-centered is-1">Pogoda na podróże po świecie</h1>
        <h2 class="subtitle has-text-centered is-5 mt-3">Prognoza pogody na najbliższe 2 tygodnie we wszystkich krajach świata i na każdym kontynencie</h2>
        <div class="columns is-multiline">
          { continents.map((el) => (
            <div class="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen">
              <div class="card">
                <div class="card-content">
                  <p class="title has-text-info">
                    { el.name }
                  </p>
                  <div class="tags">
                    { countries.filter((country) => country.continent === el.ftid).map((country) => (
                      <span class="tag is-light is-medium"><Link title={`${country.name} prognoza pogody 20 dni`} to={getSlug(country.ftid)}>{ country.name }</Link></span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}

        </div>
      </section>
    </div>
    <p>
      <Link to="/page-2/">Go to page 2</Link>
      {' '}
      <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p>
  </Layout>
);

export default IndexPage;
